var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-dialog',{attrs:{"value":_vm.isUserListInviteDialogOpen,"scrollable":"","persistent":"","max-width":"550px"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Invite User ")]),_c('v-divider'),_c('v-card-text',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createNewUser.apply(null, arguments)},"reset":function($event){$event.preventDefault();return _vm.onCancel.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"firstName"}},[_vm._v(" First Name ")]),_c('v-text-field',{attrs:{"id":"firstName","dense":"","outlined":"","clearable":"","required":"","error-messages":errors},model:{value:(_vm.profileClone.firstName),callback:function ($$v) {_vm.$set(_vm.profileClone, "firstName", $$v)},expression:"profileClone.firstName"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"lastName"}},[_vm._v(" Last Name ")]),_c('v-text-field',{attrs:{"id":"lastName","dense":"","outlined":"","clearable":"","required":"","error-messages":errors},model:{value:(_vm.profileClone.lastName),callback:function ($$v) {_vm.$set(_vm.profileClone, "lastName", $$v)},expression:"profileClone.lastName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"email"}},[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"id":"email","dense":"","outlined":"","clearable":"","required":"","prepend-inner-icon":_vm.icons.mdiEmailOutline,"error-messages":errors},model:{value:(_vm.userClone.email),callback:function ($$v) {_vm.$set(_vm.userClone, "email", $$v)},expression:"userClone.email"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Organization","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"organization"}},[_vm._v(" Organization ")]),_c('v-select',{attrs:{"id":"organization","items":_vm.organizations,"outlined":"","dense":"","item-text":"short_name","item-value":"fulcrum_id","error-messages":errors},model:{value:(_vm.userClone.orgId),callback:function ($$v) {_vm.$set(_vm.userClone, "orgId", $$v)},expression:"userClone.orgId"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"label-color font-weight-semibold d-block",attrs:{"for":"role"}},[_vm._v(" Role ")]),_c('v-radio-group',{attrs:{"id":"role","dense":"","row":"","error-messages":errors},on:{"input":function($event){return _vm.$emit('update:modelValue', $event)}},model:{value:(_vm.userClone.roleId),callback:function ($$v) {_vm.$set(_vm.userClone, "roleId", $$v)},expression:"userClone.roleId"}},_vm._l((_vm.rolesForNewUser),function(role){return _c('v-col',{key:role.id,attrs:{"cols":"6"}},[_c('v-radio',{attrs:{"label":role.roleName,"value":role.id,"ripple":""}})],1)}),1)]}}],null,true)})],1)],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":_vm.onCancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":invalid},on:{"click":_vm.createNewUser}},[_vm._v("Submit ")])],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
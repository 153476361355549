var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.usersHaveLoaded)?_c('div',[_c('v-card',{attrs:{"id":"invoice-list"}},[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between flex-wrap"},[_c('div',{staticClass:"d-flex title"},[_vm._v("All Users")]),_c('div',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"me-3",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.createItem()}}},[_c('span',[_vm._v("Add New User")])]),_c('user-filter-menu')],1)]),(_vm.usersLatestQuery)?_c('v-data-table',{attrs:{"headers":_vm.usersListTableHeaders,"items":_vm.usersLatestQuery.response.data,"options":_vm.options,"server-items-length":_vm.usersLatestQuery.response.total,"loading":_vm.usersArePending,"loading-text":"Loading... Please wait","item-key":"id","disable-sort":"","footer-props":{
        itemsPerPageOptions: [5, 10, 25, 50]
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullName)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'apps-user-view', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('small',[_vm._v(_vm._s(item.organization))])],1)],1)]}},{key:"item.role",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(
              item.role
            )) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(item.role)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(item.role)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(item.role))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.role))])],1)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{ name: 'apps-user-view', params: { id: item.id } }}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.toggleDeactivateAccount(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOffOutline)+" ")]),(item.active)?_c('span',[_vm._v("Deactivate Account")]):_vm._e(),(!item.active)?_c('span',[_vm._v("Activate Account")]):_vm._e()],1)],1)],1)],1)]}}],null,true)}):_vm._e(),_c('user-list-invite',{attrs:{"is-user-list-invite-dialog-open":_vm.isUserListInviteDialogOpen},on:{"update:isUserListInviteDialogOpen":function($event){_vm.isUserListInviteDialogOpen=$event},"update:is-user-list-invite-dialog-open":function($event){_vm.isUserListInviteDialogOpen=$event},"close-dialog":_vm.closeDialog}})],1)],1):_c('div',[_c('app-loading')],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
  import { mdiEmailOutline } from "@mdi/js";
  import { defineComponent, ref } from "@vue/composition-api";
  // Using VeeValidate for validations
  import { ValidationObserver, ValidationProvider } from "vee-validate";

  import { snackbar } from "@/components/base/snackbar/useSnackbar";
  import { useOrganizationList } from "@/composables/useOrganizations";
  import { useRoleList } from "@/composables/useRoles";
  import { useUserListInviteDialog } from "@/views/apps/user/useUser";

  export default defineComponent({
    props: {
      isUserListInviteDialogOpen: {
        type: Boolean,
        required: true
      }
    },
    components: {
      ValidationProvider,
      ValidationObserver
    },
    setup(props, { emit }) {
      const { organizations } = useOrganizationList();
      const { rolesForNewUser } = useRoleList();

      const form = ref(null);
      const overlay = ref(false);
      const {
        userClone,
        profileClone,
        getInitialUserState,
        getInitialProfileState
      } = useUserListInviteDialog();

      // form cancel
      const onCancel = () => {
        // reset the form's validation state
        form.value.reset();
        // reset the values in the form

        emit("close-dialog", false);
      };

      const createNewUser = async function () {
        try {
          overlay.value = true;

          // Adding profileClone to userClone for use
          // in before/after hook on server side
          userClone["profile"] = profileClone;
          // eslint-disable-next-line no-unused-vars
          const user = await userClone.save();
          setTimeout(() => {
            overlay.value = false;
            snackbar({
              text: `Invitation sent to ${profileClone.firstName} ${profileClone.lastName}!`,
              color: "success"
            });
          }, 3000);
        } catch (error) {
          onCancel();
          snackbar({
            text: `Unable to invite ${profileClone.firstName} ${profileClone.lastName}. Please contact your Administrator.`,
            color: "error"
          });
        } finally {
          onCancel();
        }
      };

      return {
        userClone,
        profileClone,
        createNewUser,
        getInitialUserState,
        getInitialProfileState,
        onCancel,
        organizations,
        rolesForNewUser,
        form,
        overlay,
        icons: {
          mdiEmailOutline
        }
      };
    }
  });
</script>

<template>
  <validation-observer v-slot="{ invalid }" ref="form">
    <!-- invite user dialog -->
    <v-dialog
      :value="isUserListInviteDialogOpen"
      scrollable
      persistent
      max-width="550px"
    >
      <v-card>
        <v-card-title> Invite User </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <form @submit.prevent="createNewUser" @reset.prevent="onCancel">
            <v-row>
              <v-col cols="12" md="6">
                <!-- first name -->
                <validation-provider
                  v-slot="{ errors }"
                  name="First Name"
                  rules="required"
                >
                  <label
                    for="firstName"
                    class="label-color font-weight-semibold d-block"
                  >
                    First Name
                  </label>
                  <v-text-field
                    id="firstName"
                    v-model="profileClone.firstName"
                    dense
                    outlined
                    clearable
                    required
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <!-- last name -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Last Name"
                  rules="required"
                >
                  <label
                    for="lastName"
                    class="label-color font-weight-semibold d-block"
                  >
                    Last Name
                  </label>
                  <v-text-field
                    id="lastName"
                    v-model="profileClone.lastName"
                    dense
                    outlined
                    clearable
                    required
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <!-- email address -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <label
                    for="email"
                    class="label-color font-weight-semibold d-block"
                  >
                    Email
                  </label>
                  <v-text-field
                    id="email"
                    v-model="userClone.email"
                    dense
                    outlined
                    clearable
                    required
                    :prepend-inner-icon="icons.mdiEmailOutline"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="12">
                <!-- organization -->
                <validation-provider
                  v-slot="{ errors }"
                  name="Organization"
                  rules="required"
                >
                  <label
                    for="organization"
                    class="label-color font-weight-semibold d-block"
                  >
                    Organization
                  </label>
                  <v-select
                    id="organization"
                    v-model="userClone.orgId"
                    :items="organizations"
                    outlined
                    dense
                    item-text="short_name"
                    item-value="fulcrum_id"
                    :error-messages="errors"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <label
                    for="role"
                    class="label-color font-weight-semibold d-block"
                  >
                    Role
                  </label>
                  <v-radio-group
                    id="role"
                    v-model="userClone.roleId"
                    dense
                    row
                    :error-messages="errors"
                    @input="$emit('update:modelValue', $event)"
                  >
                    <v-col
                      cols="6"
                      v-for="role in rolesForNewUser"
                      :key="role.id"
                    >
                      <v-radio
                        :label="role.roleName"
                        :value="role.id"
                        ripple
                      ></v-radio>
                    </v-col>
                  </v-radio-group>
                </validation-provider>
              </v-col>
            </v-row>
          </form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="secondary" @click="onCancel"> Cancel </v-btn>
          <v-btn text color="primary" :disabled="invalid" @click="createNewUser"
            >Submit
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </validation-observer>
</template>

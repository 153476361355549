<script>
  import {
    mdiAccountOffOutline,
    mdiDotsVertical,
    mdiFileDocumentOutline
  } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";

  import AppLoading from "@/components/base/loading/AppLoading.vue";
  import UserFilterMenu from "@/components/common/filter-menu/UserFilterMenu.vue";
  import { usePagination } from "@/composables/usePagination";
  import { avatarText } from "@/helpers/filter";
  import { store } from "@/store";
  import {
    useUserListInviteDialog,
    useUsersList
  } from "@/views/apps/user/useUser";

  import UserListInvite from "./UserListInvite.vue";

  export default defineComponent({
    components: {
      UserFilterMenu,
      UserListInvite,
      AppLoading
    },
    setup() {
      const {
        usersListTableHeaders,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        usersArePending,
        usersHaveLoaded,
        usersPaginationData,
        usersLatestQuery
      } = useUsersList();
      const { selectedNumberOfItemsPerPage, page, options } = usePagination();

      const { isUserListInviteDialogOpen, createItem, closeDialog } =
        useUserListInviteDialog();

      watch(options, (value) => {
        store.dispatch("users/updatePagination", value);
      });

      let toggleDeactivateAccount = async function (user) {
        let id = user.id;
        let userClone = user.clone();

        //Strip all fields from the user so that we're only patching "Active" due to preventChanges() on other fields on the backend
        for (var field in userClone) delete userClone[field];

        //Add the user id back in so we know what to patch
        userClone.id = id;

        if (user.active) {
          userClone.active = false;

          userClone.save();
        }

        if (!user.active) {
          userClone.active = true;

          userClone.save();
        }
      };

      return {
        usersListTableHeaders,
        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,
        usersArePending,
        usersHaveLoaded,
        usersPaginationData,
        usersLatestQuery,
        avatarText,

        // usePagination
        selectedNumberOfItemsPerPage,
        page,
        options,

        isUserListInviteDialogOpen,
        createItem,
        closeDialog,

        toggleDeactivateAccount,

        icons: {
          mdiDotsVertical,
          mdiFileDocumentOutline,
          mdiAccountOffOutline
        }
      };
    }
  });
</script>

<template>
  <div v-if="usersHaveLoaded">
    <v-card id="invoice-list">
      <v-card-text class="d-flex align-center justify-space-between flex-wrap">
        <!-- create contract -->
        <div class="d-flex title">All Users</div>
        <div class="d-flex">
          <v-btn small color="primary" class="me-3" @click="createItem()">
            <span>Add New User</span>
          </v-btn>

          <user-filter-menu />
        </div>
      </v-card-text>

      <!-- data table -->
      <v-data-table
        v-if="usersLatestQuery"
        :headers="usersListTableHeaders"
        :items="usersLatestQuery.response.data"
        :options.sync="options"
        :server-items-length="usersLatestQuery.response.total"
        :loading="usersArePending"
        loading-text="Loading... Please wait"
        item-key="id"
        disable-sort
        :footer-props="{
          itemsPerPageOptions: [5, 10, 25, 50]
        }"
      >
        <!-- user -->
        <!-- name -->
        <template #[`item.fullName`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="item.avatar"></v-img>
              <span v-else class="font-weight-medium">{{
                avatarText(item.fullName)
              }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'apps-user-view', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullName }}
              </router-link>
              <small>{{ item.organization }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.role`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(
                item.role
              )}--text me-3`"
            >
              <v-icon size="18" :color="resolveUserRoleVariant(item.role)">
                {{ resolveUserRoleIcon(item.role) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ item.role }}</span>
          </div>
        </template>

        <!-- active -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{ name: 'apps-user-view', params: { id: item.id } }"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="toggleDeactivateAccount(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiAccountOffOutline }}
                  </v-icon>
                  <span v-if="item.active">Deactivate Account</span>
                  <span v-if="!item.active">Activate Account</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>

      <!-- user invite dialog -->
      <user-list-invite
        :is-user-list-invite-dialog-open.sync="isUserListInviteDialogOpen"
        @close-dialog="closeDialog"
      ></user-list-invite>
    </v-card>
  </div>
  <div v-else>
    <app-loading></app-loading>
  </div>
</template>

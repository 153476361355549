<script>
  import {
    mdiCheckboxBlankOutline,
    mdiClose,
    mdiFilterVariant,
    mdiMagnify
  } from "@mdi/js";
  import { defineComponent, watch } from "@vue/composition-api";
  import { watchDebounced } from "@vueuse/core";
  import { PerfectScrollbar } from "vue2-perfect-scrollbar";

  // composables
  import {
    useFilterMenuToggle,
    useOrganizationFilter,
    useRoleFilter,
    useSearchTextFilter
  } from "@/components/common/filter-menu/useFilterMenu";
  import { store } from "@/store";

  export default defineComponent({
    components: {
      PerfectScrollbar
    },
    setup() {
      const {
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll
      } = useFilterMenuToggle();

      const { filterText, resetTextFilter } = useSearchTextFilter();

      const {
        organizations,
        selectedOrganizations,
        selectAllOrganizationsIcon,
        toggleAllOrganizations,
        resetOrganizationFilter
      } = useOrganizationFilter();

      const {
        roles,
        selectedRoles,
        selectAllRolesIcon,
        toggleAllRoles,
        resetRoleFilter
      } = useRoleFilter();

      const clearAllFilters = () => {
        resetOrganizationFilter();
        resetRoleFilter();
        store.dispatch("users/clearAllFilters");
      };

      watchDebounced(
        filterText,
        (value) => {
          store.dispatch("users/filterText", value);
        },
        { debounce: 500 }
      );

      watch(selectedOrganizations, (value) => {
        store.dispatch("organizations/filterOrganizations", value);
      });

      watch(selectedRoles, (value) => {
        store.dispatch("roles/filterRoles", value);
      });

      return {
        clearAllFilters,
        // useFilterMenuToggle
        isFilterDrawerOpen,
        expandButtonLabel,
        clearFiltersButtonLabel,
        perfectScrollbarOptions,
        filtersPanel,
        filtersLength,
        toggleAll,

        // useSearchTextFilter
        filterText,
        resetTextFilter,

        // useOrganizationFilter
        organizations,
        selectedOrganizations,
        selectAllOrganizationsIcon,
        toggleAllOrganizations,
        resetOrganizationFilter,

        // useRoleFilter
        roles,
        selectedRoles,
        selectAllRolesIcon,
        toggleAllRoles,
        resetRoleFilter,

        icons: {
          mdiClose,
          mdiFilterVariant,
          mdiMagnify,
          mdiCheckboxBlankOutline
        }
      };
    }
  });
</script>

<template>
  <div class="app-customizer">
    <v-btn
      small
      outlined
      color="primary"
      @click="isFilterDrawerOpen = !isFilterDrawerOpen"
    >
      <v-icon left>{{ icons.mdiFilterVariant }}</v-icon>
      Filter List
    </v-btn>

    <!-- navigation drawer -->
    <v-navigation-drawer
      v-model="isFilterDrawerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <!-- heading -->
      <div class="app-customizer-header customizer-section py-3">
        <h6 class="font-weight-semibold text-xl text--primary">FILTERS</h6>
        <span class="text--secondary">
          Set your filters and view the results
        </span>
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isFilterDrawerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <!-- filter list content -->
      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!-- text search -->
        <div class="customizer-section">
          <v-text-field
            v-model="filterText"
            :prepend-inner-icon="icons.mdiMagnify"
            clearable
            single-line
            dense
            outlined
            hide-details
            placeholder="Search users by email"
          ></v-text-field>
        </div>

        <!-- expand/collapse all -->
        <div class="text-center d-flex justify-space-between pb-4">
          <v-btn
            @click="clearAllFilters"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ clearFiltersButtonLabel }}
          </v-btn>
          <v-btn
            @click="toggleAll"
            x-small
            text
            class="text-xs secondary--text"
          >
            {{ expandButtonLabel }}
          </v-btn>
        </div>

        <v-expansion-panels v-model="filtersPanel" flat multiple>
          <!-- organization multi-select -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    selectedOrganizations.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  ORGANIZATION
                </span>
                <v-chip
                  v-if="selectedOrganizations.length > 0"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="resetOrganizationFilter"
                >
                  {{ selectedOrganizations.length }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="my-1">
                <v-select
                  v-model="selectedOrganizations"
                  :items="organizations"
                  item-value="fulcrum_id"
                  item-text="short_name"
                  label="Select Organization(s)"
                  multiple
                  outlined
                  dense
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggleAllOrganizations">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedOrganizations.length > 0
                              ? 'indigo darken-4'
                              : ''
                          "
                        >
                          {{ selectAllOrganizationsIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="my-2"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.full_name }}</span>

                    <span v-if="index === 1" class="grey--text text-caption">
                      &nbsp;(+{{ selectedOrganizations.length - 1 }} more)
                    </span>
                  </template>
                </v-select>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!-- role -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="d-flex align-center justify-space-between">
                <span
                  :class="
                    selectedRoles.length > 0
                      ? 'primary--text text-xs font-weight-semibold'
                      : 'text-xs font-weight-semibold'
                  "
                >
                  ROLE
                </span>
                <v-chip
                  v-if="selectedRoles.length > 0"
                  class="mr-5"
                  close
                  small
                  color="primary"
                  @click:close="resetRoleFilter"
                >
                  {{ selectedRoles.length }}
                </v-chip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="my-1">
                <v-select
                  v-model="selectedRoles"
                  :items="roles"
                  item-value="id"
                  item-text="roleName"
                  label="Select Role(s)"
                  multiple
                  outlined
                  dense
                  class="mt-3"
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggleAllRoles">
                      <v-list-item-action>
                        <v-icon
                          :color="
                            selectedRoles.length > 0 ? 'indigo darken-4' : ''
                          "
                        >
                          {{ selectAllRolesIcon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>

                  <template v-slot:selection="{ item, index }">
                    <span v-if="index === 0">{{ item.roleName }}</span>

                    <span v-if="index === 1" class="grey--text text-caption">
                      &nbsp;(+{{ selectedRoles.length - 1 }} more)
                    </span>
                  </template>
                </v-select>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<style lang="scss" scoped>
  @import "~vuetify/src/styles/styles.sass";

  .ps-customizer {
    height: calc(100% - 81px) !important;
  }

  .app-customizer-toggler {
    position: fixed;
    top: 50%;
    transform: translateX(-50%);
    background: var(--v-primary-base);
    @include ltr() {
      right: -22px;
    }
    @include rtl() {
      left: 20px;
    }
  }

  @include theme(app-customizer-drawer) using ($material) {
    background-color: map-deep-get($material, "cards");
  }

  .app-customizer {
    z-index: 7;

    .v-label {
      font-size: 0.875rem;
    }

    .app-customizer-header {
      position: relative;
      .icon-customizer-close {
        position: absolute;
        @include ltr() {
          right: 20px;
        }
        @include rtl() {
          left: 20px;
        }
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .customizer-section {
      padding: 15px;
    }

    // Fixes Overlay is shown below SystemBar
    @at-root {
      .v-overlay {
        & + .v-application--wrap > .v-system-bar {
          z-index: 6 !important;
        }
      }
    }
  }

  .v-application.theme--light {
    .v-expansion-panel {
      &::before {
        box-shadow: none !important;
      }
      &--active {
        box-shadow: none !important;
      }
    }
  }
</style>
